import React from "react";

/*** COMPONENTS ***/
import Main from "../components/main";
import SEO from "../components/seo";
import "../styles/about.css";

const About = () => (
  <Main>
    <SEO title="About" />
    <div class="container-about">
      <div class="grid-item-h">
        <h1 class="heading">
          HI. I'M JOHANNA, A SOFTWARE DEVLOPER WHO IS PASSIONATE ABOUT
          CONTINUOUSLY GROWING MY SKILLSET.
        </h1>
      </div>

      <div class="grid-item-early">
        <h5 class="rubrik">1994-2014</h5>
        <p class="coc">
          When I was seven, I had decided to become a cartoonist when I grew up.
          I read the newspaper from the end to the beginning so that I could see
          and read the cartoon section first. The best part of the newspaper. In
          school, I always tried to come up with a way to include drawing, by
          turning information into art in some way or another. The teachers told
          me I had to stop since it took up too much of my time, but I remember
          how I insisted to keep ongoing.
          <br />
          <br />
          At the age of 14, I had found several more interests than just
          drawing. I had tried all kinds of sports, but the ones I liked the
          most was football, skiing and floorball. Between school and practices,
          I also had found an interest in gaming. I played League of Legends and
          Diablo with friends, but what I enjoyed most was single-player games
          such as Grand Theft Auto and FarCry.
          <br />
          <br />
          At the aged of 21, I had taken two years to break from studying and
          gathered some work experience at a company called ABB and I was ready
          to hit the books again. I had left the idea of becoming a cartoonist
          some years ago, but I knew that I wanted to find an education that
          allowed me to express my creativity in one way or another.
        </p>
      </div>

      <div class="grid-item-late">
        <h5 class="rubrik">2015-2021</h5>
        <p class="coc">
          I started studying for a Master of Science in Media Technology at{" "}
          <strong>Linköpings University</strong>. I read my first course in
          programming and realized how much you can do with it, how you can
          combine technology and programming with creativity. During my masters,
          I started to grow an interest in web development, and frontend
          programming in general. I did my master thesis for the OpenSpace
          project, where my associate and I developed a messaging protocol.
          During the same time, I worked as a consultant at We Know IT as a web
          developer and I knew that I had found the subject I wanted to continue
          to develop my knowledge within after my graduation: Web Development.
          <br />
          <br />
          This resulted in my first job as a junior frontend web developer at{" "}
          <strong>BookBeat</strong>, where I work until this day.
        </p>
      </div>
    </div>
  </Main>
);

export default About;
